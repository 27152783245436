/**
* Template Name: Eterna - v2.0.0
* Template URL: https://bootstrapmade.com/eterna-free-multipurpose-bootstrap-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
}

a {
    color: #00ADEF;
}

a:hover {
    color: #00ADEF;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Raleway", sans-serif;
}
.opacity-0-0{
    opacity: 0.0;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
}

.back-to-top i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: #00ADEF;
    color: #fff;
    transition: all 0.4s;
}

.back-to-top i:hover {
    background: #00ADEF;
    color: #fff;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
    background: #5b5b5b;
    padding: 10px 0;
    font-size: 14px;
}

#topbar .contact-info {
    color: #fff;
}

#topbar .contact-info a {
    line-height: 1;
    color: #fff;
    transition: 0.3s;
}

#topbar .contact-info a:hover {
    color: #00ADEF;
}

#topbar .contact-info i {
    color: #00ADEF;
    padding: 4px;
}

#topbar .contact-info .icofont-phone {
    padding-left: 20px;
}

#topbar .social-links a {
    color: #fff;
    padding: 4px 0 4px 16px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
}

#topbar .social-links a:hover {
    color: #00ADEF;
}

#topbar .social-links a:first-child {
    border-left: 0;
}
input#searchMenu:focus {
    outline-width: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    height: 70px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
    position: relative;
}

#header .logo h1 {
    font-size: 26px;
    padding: 0 0 0 0px;
    margin: 11px 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;

}

#header .logo h1 a,
#header .logo h1 a:hover {
    color: #545454;
    text-decoration: none;
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/* Desktop Navigation */
.nav-menu,
.nav-menu * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.nav-menu>ul>li {
    position: relative;
    white-space: nowrap;
    float: left;
}

.nav-menu a {
    display: block;
    position: relative;
    color: #545454;
    padding: 13px 0 15px 25px;
    transition: 0.3s;
    font-size: 14px;
    font-family: "Poppins", sans-serif;
}

.nav-menu a:hover,
.nav-menu .active>a,
.nav-menu li:hover>a {
    color: #00ADEF;
    text-decoration: none;
}

.nav-menu .drop-down ul {
    display: block;
    position: absolute;
    left: 20px;
    top: calc(100% + 30px);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    padding: 10px 0;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: ease all 0.3s;
}

.nav-menu .drop-down:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.nav-menu .drop-down li {
    min-width: 180px;
    position: relative;
}

.nav-menu .drop-down ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
}

.nav-menu .drop-down ul a:hover,
.nav-menu .drop-down ul .active>a,
.nav-menu .drop-down ul li:hover>a {
    color: #00ADEF;
}

.nav-menu .drop-down>a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 5px;
}

.nav-menu .drop-down .drop-down ul {
    top: 0;
    left: calc(100% - 30px);
}

.nav-menu .drop-down .drop-down:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
}

.nav-menu .drop-down .drop-down>a {
    padding-right: 35px;
}

.nav-menu .drop-down .drop-down>a:after {
    content: "\eaa0";
    font-family: IcoFont;
    position: absolute;
    right: 15px;
}

@media (max-width: 1366px) {
    .nav-menu .drop-down .drop-down ul {
        left: -90%;
    }

    .nav-menu .drop-down .drop-down:hover>ul {
        left: -100%;
    }

    .nav-menu .drop-down .drop-down>a:after {
        content: "\ea9d";
    }
}

/* Mobile Navigation */
.mobile-nav-toggle {
    position: fixed;
    right: 15px;
    top: 20px;
    z-index: 9998;
    border: 0;
    background: none;
    font-size: 24px;
    transition: all 0.4s;
    outline: none !important;
    line-height: 1;
    cursor: pointer;
    text-align: right;
}

.mobile-nav-toggle i {
    color: #00ADEF;
}

.mobile-nav {
    position: fixed;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    z-index: 9999;
    overflow-y: auto;
    background: #fff;
    transition: ease-in-out 0.2s;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
    padding: 10px 0;
}

.mobile-nav * {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mobile-nav a {
    display: block;
    position: relative;
    color: #545454;
    padding: 10px 20px;
    font-weight: 500;
    outline: none;
}

.mobile-nav a:hover,
.mobile-nav .active>a,
.mobile-nav li:hover>a {
    color: #00ADEF;
    text-decoration: none;
}

.mobile-nav .drop-down>a:after {
    content: "\ea99";
    font-family: IcoFont;
    padding-left: 10px;
    position: absolute;
    right: 15px;
}

.mobile-nav .active.drop-down>a:after {
    content: "\eaa1";
}

.mobile-nav .drop-down>a {
    padding-right: 35px;
}

.mobile-nav .drop-down ul {
    display: none;
    overflow: hidden;
}

.mobile-nav .drop-down li {
    padding-left: 20px;
}

.mobile-nav-overly {
    width: 100%;
    height: 100%;
    z-index: 9997;
    top: 0;
    left: 0;
    position: fixed;
    background: rgba(59, 59, 59, 0.6);
    overflow: hidden;
    display: none;
    transition: ease-in-out 0.2s;
}

.mobile-nav-active {
    overflow: hidden;
}

.mobile-nav-active .mobile-nav {
    opacity: 1;
    visibility: visible;
}

.mobile-nav-active .mobile-nav-toggle i {
    color: #fff;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    width: 100%;
    height: 70vh;
    background-color: rgba(59, 59, 59, 0.8);
    overflow: hidden;
    padding: 0;
    margin-bottom: -120px;
    position: relative;
    z-index: 1;
}

#hero .carousel-item {
    width: 100%;
    height: 70vh;
    background-size: 100% 100% !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
}


#hero .carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    padding-bottom: 120px;
}

#hero .carousel-content {
    text-align: center;
}

#hero h2 {
    color: #2e2e2e;
    margin-bottom: 30px;
    font-size: 48px;
    font-weight: 700;
}

#hero h2 span {
    color: #00ADEF;
}

#hero p {
    width: 80%;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
    margin: 0 auto 30px auto;
    color: arken(#545454, 15);
}

#hero .carousel-inner .carousel-item {
    transition-property: opacity;
    background-position: center top;
}

#hero .carousel-inner .carousel-item,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
    opacity: 0;
}

#hero .carousel-inner .active,
#hero .carousel-inner .carousel-item-next.carousel-item-left,
#hero .carousel-inner .carousel-item-prev.carousel-item-right {
    opacity: 1;
    transition: 0.5s;
}

#hero .carousel-inner .carousel-item-next,
#hero .carousel-inner .carousel-item-prev,
#hero .carousel-inner .active.carousel-item-left,
#hero .carousel-inner .active.carousel-item-right {
    left: 0;
    transform: translate3d(0, 0, 0);
}

#hero .carousel-control-prev,
#hero .carousel-control-next {
    width: 10%;
}

#hero .carousel-control-next-icon,
#hero .carousel-control-prev-icon {
    background: none;
    font-size: 48px;
    line-height: 1;
    width: auto;
    height: auto;
    color: #545454;
}

#hero .carousel-indicators li {
    cursor: pointer;
    background: #00ADEF;
    overflow: hidden;
    border: 0;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    margin-bottom: 180px;
    opacity: .2;
}

#hero .carousel-indicators li.active {
    opacity: 1;
}

#hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 50px;
    transition: 0.5s;
    line-height: 1;
    margin: 10px;
    color: #fff;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    border: 2px solid #00ADEF;
    background: #00ADEF;
}

#hero .btn-get-started:hover {
    background: transparent;
    color: #00ADEF;
    text-decoration: none;
}
#hero .btn-get-started.versao2:hover {
    background: #ffffff;
    color: #00ADEF;
    text-decoration: none;
}

@media (max-height: 768px),
(max-width: 1200px) {
    #hero {
        margin-bottom: 60px;
    }

    #hero .carousel-container {
        padding-bottom: 0;
    }

    #hero .carousel-indicators li {
        margin-bottom: 0;
    }
}

@media (max-width: 768px) {
    #hero {
        height: 40vh !important;
    }

    #hero .carousel-item {
        height: 40vh !important;
    }
}
@media (max-width: 481px) {
    #hero {
        height: 25vh !important;
    }

    #hero .carousel-item {
        height: 25vh !important;
    }

    #hero .btn-get-started {
        font-size: 8px;
        padding: 5px 21px;
        margin: 2px;
        margin-top: 82px;
    }
}

@media (min-width: 1024px) {
    #hero p {
        width: 50%;
    }

    #hero .carousel-control-prev,
    #hero .carousel-control-next {
        width: 5%;
    }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
    padding: 0 0 80px 0;
}

.section-bg {
    background-color: white;
}

.section-title {
    text-align: center;
    padding-bottom: 30px;
}

.section-title h2 {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    font-family: "Poppins", sans-serif;
}

.section-title h2::after {
    content: '';
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #00ADEF;
    bottom: 0;
    left: calc(50% - 25px);
}

.section-title p {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
    padding: 20px 0 20px 0;
    background: #f7f7f7;
    border-bottom: 1px solid #ededed;
    margin-bottom: 40px;
}

.breadcrumbs h2 {
    font-size: 28px;
    font-weight: 700;
    color: #545454;
}

.breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0 0 5px 0;
    margin: 0;
    font-size: 14px;
}

.breadcrumbs ol li+li {
    padding-left: 10px;
}

.breadcrumbs ol li+li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6e6e6e;
    content: "/";
}

/*--------------------------------------------------------------
# Featured
--------------------------------------------------------------*/
.featured {
    position: relative;
    z-index: 2;
}

.featured .icon-box {
    padding: 40px 30px;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    background: #fff;
    transition: all ease-in-out 0.3s;
}

.featured .icon-box i {
    color: #00ADEF;
    font-size: 42px;
    margin-bottom: 15px;
    display: block;
}

.featured .icon-box h3 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 20px;
}

.featured .icon-box h3 a,.featured .icon-box a h3 {
    color: #545454;
    transition: 0.3s;
}

.featured .icon-box p {
    color: #545454;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.featured .icon-box:hover {
    background: #00ADEF;
}

.featured .icon-box:hover i,
.featured .icon-box:hover h3 a,
.featured .icon-box:hover a h3,
.featured .icon-box:hover p {
    color: #fff;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
    font-weight: 600;
    font-size: 26px;
    color: #545454;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-bottom: 10px;
}

.about .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #00ADEF;
}

.about .content p:last-child {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
# Our Services
--------------------------------------------------------------*/
.services .icon-box {
    text-align: center;
    border: 1px solid #ededed;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
}

.services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #00ADEF;
    border-radius: 5px;
    transition: all .3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
}

.services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
}

.services .icon-box .icon::before {
    position: absolute;
    content: '';
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: #cce8f3;
    border-radius: 5px;
    transition: all .3s ease-out 0s;
    transform: translateZ(-1px);
}

.services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
}

.services .icon-box h4 a {
    color: #545454;
}

.services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.services .icon-box:hover {
    background: transparent;
    border-color: transparent;
}

.services .icon-box:hover .icon {
    background: #fff;
}

.services .icon-box:hover .icon i {
    color: #00ADEF;
}

.services .icon-box:hover .icon::before {
    background: #cce8f3;
}

.services .icon-box:hover h4 a,
.services .icon-box:hover p {
    color: #fff;
}

/*--------------------------------------------------------------
# Our Clients
--------------------------------------------------------------*/
.clients .owl-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}

.clients .owl-item img {
    opacity: 0.5;
    width: 80%;
    transition: 0.3s;
    -webkit-filter: grayscale(100);
    filter: grayscale(100);
}

.clients .owl-item img:hover {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
}

.clients .owl-nav,
.clients .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.clients .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.clients .owl-dot.active {
    background-color: #00ADEF !important;
}

/*--------------------------------------------------------------
# Counts
--------------------------------------------------------------*/
.counts .count-box {
    box-shadow: -10px -5px 40px 0 rgba(0, 0, 0, 0.1);
    padding: 30px;
    width: 100%;
}

.counts .count-box i {
    display: block;
    font-size: 30px;
    color: #00ADEF;
    float: left;
}

.counts .count-box span {
    font-size: 42px;
    line-height: 24px;
    display: block;
    font-weight: 700;
    color: #545454;
    margin-left: 50px;
}

.counts .count-box p {
    padding: 30px 0 0 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
}

.counts .count-box a {
    font-weight: 600;
    display: block;
    margin-top: 20px;
    color: #7a7a7a;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
    transition: ease-in-out 0.3s;
}

.counts .count-box a:hover {
    color: #a1a1a1;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 40px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
}

.testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 50px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
}

.testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: white;
    font-size: 26px;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px 0 0 0;
    padding: 0;
}

/*--------------------------------------------------------------
# Our Skills
--------------------------------------------------------------*/
.skills .content h3 {
    font-weight: 700;
    font-size: 26px;
    color: #545454;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
}

.skills .content ul {
    list-style: none;
    padding: 0;
}

.skills .content ul li {
    padding-bottom: 10px;
}

.skills .content ul i {
    font-size: 20px;
    padding-right: 4px;
    color: #00ADEF;
}

.skills .content p:last-child {
    margin-bottom: 0;
}

.skills .progress {
    height: 62px;
    display: block;
    background: none;
}

.skills .progress .skill {
    padding: 0;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    display: block;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
    color: #545454;
}

.skills .progress .skill .val {
    float: right;
    font-style: normal;
}

.skills .progress-bar-wrap {
    background: #e0e0e0;
}

.skills .progress-bar {
    width: 1px;
    height: 10px;
    transition: .9s;
    background-color: #00ADEF;
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio .portfolio-item {
    margin-bottom: 30px;
}

.portfolio #portfolio-flters {
    padding: 0;
    margin: 0 auto 25px auto;
    list-style: none;
    text-align: center;
    background: white;
    border-radius: 50px;
    padding: 2px 15px;
}

.portfolio #portfolio-flters li {
    cursor: pointer;
    display: inline-block;
    padding: 8px 20px 12px 20px;
    font-size: 15px;
    font-weight: 500;
    line-height: 1;
    color: #444444;
    margin: 0 4px 8px 4px;
    transition: all ease-in-out 0.3s;
    border-radius: 50px;
    background: #f2f2f2;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
    background: #00ADEF;
    color: #fff;
}
li.filter-active{
    font-weight: 800;
}
li.filter-active a{
    color: #00ADEF !important;
}

.portfolio #portfolio-flters li:last-child {
    margin-right: 0;
}

.portfolio .portfolio-wrap {
    transition: 0.3s;
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: transparent;
}

.portfolio .portfolio-wrap::before {
    content: "";
    background: rgba(84, 84, 84, 0.6);
    position: absolute;
    left: 30px;
    right: 30px;
    top: 30px;
    bottom: 30px;
    transition: all ease-in-out 0.3s;
    z-index: 2;
    opacity: 0;
}

.portfolio .portfolio-wrap .portfolio-info {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 3;
    transition: all ease-in-out 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.portfolio .portfolio-wrap .portfolio-info::before {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: 35px;
    left: 35px;
    border-top: 3px solid #fff;
    border-left: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info::after {
    display: block;
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    bottom: 35px;
    right: 35px;
    border-bottom: 3px solid #fff;
    border-right: 3px solid #fff;
    transition: all 0.5s ease 0s;
    z-index: 9994;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
    color: #ffffff;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.portfolio .portfolio-wrap .portfolio-links {
    text-align: center;
    z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links a {
    color: #fff;
    margin: 0 2px;
    font-size: 28px;
    display: inline-block;
    transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links a:hover {
    color: #00ADEF;
}

.portfolio .portfolio-wrap:hover::before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info {
    opacity: 1;
}

.portfolio .portfolio-wrap:hover .portfolio-info::before {
    top: 15px;
    left: 15px;
}

.portfolio .portfolio-wrap:hover .portfolio-info::after {
    bottom: 15px;
    right: 15px;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details .portfolio-title {
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-details-carousel .owl-nav,
.portfolio-details .portfolio-details-carousel .owl-dots {
    margin-top: 5px;
    text-align: center;
}

.portfolio-details .portfolio-details-carousel .owl-dot {
    display: inline-block;
    margin: 0 5px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #ddd !important;
}

.portfolio-details .portfolio-details-carousel .owl-dot.active {
    background-color: #00ADEF !important;
}

.portfolio-details .portfolio-info {
    padding-top: 45px;
}

.portfolio-details .portfolio-info h3 {
    font-size: 22px;
    font-weight: 400;
    margin-bottom: 20px;
}

.portfolio-details .portfolio-info ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
}

.portfolio-details .portfolio-info ul li+li {
    margin-top: 10px;
}

.portfolio-details .portfolio-info p {
    font-size: 15px;
    padding: 15px 0 0 0;
}

@media (max-width: 992px) {
    .portfolio-details .portfolio-info {
        padding-top: 20px;
    }
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
    padding-top: 40px;
}

.pricing .box {
    padding: 40px;
    margin-bottom: 30px;
    box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
    background: #fff;
    text-align: center;
}

.pricing h3 {
    font-weight: 300;
    margin-bottom: 15px;
    font-size: 28px;
}

.pricing h4 {
    font-size: 46px;
    color: #00ADEF;
    font-weight: 400;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 25px;
}

.pricing h4 span {
    color: #bababa;
    font-size: 18px;
    display: block;
}

.pricing ul {
    padding: 0;
    list-style: none;
    color: #999;
    text-align: left;
    line-height: 20px;
}

.pricing ul li {
    padding-bottom: 12px;
}

.pricing ul i {
    color: #00ADEF;
    font-size: 18px;
    padding-right: 4px;
}

.pricing ul .na {
    color: #ccc;
}

.pricing ul .na i {
    color: #ccc;
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .buy-btn {
    background: #545454;
    display: inline-block;
    padding: 8px 30px;
    border-radius: 20px;
    color: #fff;
    transition: none;
    font-size: 14px;
    font-weight: 400;
    font-family: "Raleway", sans-serif;
    transition: 0.3s;
}

.pricing .buy-btn:hover {
    background: #00ADEF;
}

.pricing .featured {
    z-index: 10;
    margin: -30px -5px 0 -5px;
}

.pricing .featured .buy-btn {
    background: #00ADEF;
}

.pricing .featured .buy-btn:hover {
    background: #ee8b7a;
}

@media (max-width: 992px) {
    .pricing .box {
        max-width: 60%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 767px) {
    .pricing .box {
        max-width: 80%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 420px) {
    .pricing .box {
        max-width: 100%;
        margin: 0 auto 30px auto;
    }
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
    text-align: center;
    margin-bottom: 20px;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.1);
    padding: 30px 20px;
    background: #fff;
}

.team .member img {
    max-width: 60%;
    border-radius: 50%;
    margin: 0 0 30px 0;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 2px;
    font-size: 18px;
}

.team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
}

.team .member p {
    padding-top: 10px;
    font-size: 14px;
    font-style: italic;
    color: #aaaaaa;
}

.team .member .social {
    margin-top: 15px;
}

.team .member .social a {
    color: #919191;
    transition: 0.3s;
}

.team .member .social a:hover {
    color: #00ADEF;
}

.team .member .social i {
    font-size: 18px;
    margin: 0 2px;
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info-box {
    color: #444444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 20px 0 30px 0;
}

.contact .info-box i {
    font-size: 32px;
    color: #00ADEF;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #fef5f4;
}

.contact .info-box h3 {
    font-size: 20px;
    color: #777777;
    font-weight: 700;
    margin: 10px 0;
}

.contact .info-box p {
    padding: 0;
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
}

.contact .php-email-form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
}

.contact .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
}

.contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
}

.contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
}

.contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
}

.contact .php-email-form input::focus,
.contact .php-email-form textarea::focus {
    background-color: #00ADEF;
}

.contact .php-email-form input {
    padding: 20px 15px;
}

.contact .php-email-form textarea {
    padding: 12px 15px;
}

.contact .php-email-form button[type="submit"] {
    background: #00ADEF;
    border: 0;
    border-radius: 50px;
    padding: 10px 24px;
    color: #fff;
    transition: 0.4s;
}

.contact .php-email-form button[type="submit"]:hover {
    background: #00ADEF;
}

@-webkit-keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes animate-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
# Produto
--------------------------------------------------------------*/
.produto {
    padding-bottom: 30px;
}

.produto .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.produto .entry .entry-img {
    max-height: 400px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
}

.produto .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
}

.produto .entry .entry-title a {
    color: #545454;
    transition: 0.3s;
}

.produto .entry .entry-title a:hover {
    color: #00ADEF;
}

.produto .entry .entry-meta {
    margin-bottom: 15px;
    color: #bababa;
}

.produto .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
}

.produto .entry .entry-meta ul li+li {
    padding-left: 20px;
}

.produto .entry .entry-meta i {
    font-size: 14px;
    padding-right: 4px;
}

.produto .entry .entry-meta a {
    color: #a1a1a1;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
}

.produto .entry .entry-content p {
    line-height: 24px;
}

.produto .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
}

.produto .entry .entry-content .read-more a {
    display: inline-block;
    background: #00ADEF;
    color: #fff;
    padding: 6px 20px 8px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 50px;
}

.produto .entry .entry-content .read-more a:hover {
    background: #00ADEF;
}

.produto .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
}

.produto .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
}

.produto .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
}

.produto .entry .entry-content blockquote .quote-left {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 36px;
    color: #e7e7e7;
}

.produto .entry .entry-content blockquote .quote-right {
    position: absolute;
    right: 20px;
    bottom: 20px;
    font-size: 36px;
    color: #e7e7e7;
}

.produto .entry .entry-content blockquote::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #545454;
    margin-top: 20px;
    margin-bottom: 20px;
}

.produto .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
}

.produto .entry .entry-footer i {
    color: #a1a1a1;
    display: inline;
}

.produto .entry .entry-footer a {
    color: #cecece;
    transition: 0.3s;
}

.produto .entry .entry-footer a:hover {
    color: #00ADEF;
}

.produto .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
}

.produto .entry .entry-footer .cats li {
    display: inline-block;
}

.produto .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
}

.produto .entry .entry-footer .tags li {
    display: inline-block;
}

.produto .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
}

.produto .entry .entry-footer .share {
    font-size: 16px;
}

.produto .entry .entry-footer .share i {
    padding-left: 5px;
}

.produto .entry-single {
    margin-bottom: 30px;
}

.produto .produto-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.produto .produto-author img {
    width: 120px;
}

.produto .produto-author h4 {
    margin-left: 140px;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
}

.produto .produto-author .social-links {
    margin: 0 0 5px 140px;
}

.produto .produto-author .social-links a {
    color: #bababa;
}

.produto .produto-author p {
    margin-left: 140px;
    font-style: italic;
    color: #b7b7b7;
}

.produto .produto-comments {
    margin-bottom: 30px;
}

.produto .produto-comments .comments-count {
    font-weight: bold;
}

.produto .produto-comments .comment {
    margin-top: 30px;
    position: relative;
}

.produto .produto-comments .comment .comment-img {
    width: 50px;
}

.produto .produto-comments .comment h5 {
    margin-left: 65px;
    font-size: 16px;
    margin-bottom: 2px;
}

.produto .produto-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
}

.produto .produto-comments .comment h5 a:hover {
    color: #00ADEF;
}

.produto .produto-comments .comment h5 .reply {
    padding-left: 10px;
    color: #545454;
}

.produto .produto-comments .comment time {
    margin-left: 65px;
    display: block;
    font-size: 14px;
    color: #bababa;
    margin-bottom: 5px;
}

.produto .produto-comments .comment p {
    margin-left: 65px;
}

.produto .produto-comments .comment.comment-reply {
    padding-left: 40px;
}

.produto .produto-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.produto .produto-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
}

.produto .produto-comments .reply-form p {
    font-size: 14px;
}

.produto .produto-comments .reply-form input {
    border-radius: 50px;
    padding: 20px 10px 20px 20px;
    font-size: 14px;
}

.produto .produto-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #f5bab0;
}

.produto .produto-comments .reply-form textarea {
    border-radius: 5px;
    padding: 10px 12px;
    font-size: 14px;
}

.produto .produto-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #f5bab0;
}

.produto .produto-comments .reply-form .form-group {
    margin-bottom: 25px;
}

.produto .produto-comments .reply-form .btn-primary {
    border-radius: 50px;
    padding: 10px 30px;
    border: 0;
    background-color: #545454;
}

.produto .produto-comments .reply-form .btn-primary:hover {
    background-color: #00ADEF;
}

.produto .produto-pagination {
    color: #878787;
}

.produto .produto-pagination ul {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.produto .produto-pagination li {
    border: 1px solid white;
    margin: 0 5px;
    transition: 0.3s;
}

.produto .produto-pagination li.active {
    background: white;
}

.produto .produto-pagination li a {
    color: #aaaaaa;
    padding: 7px 16px;
    display: inline-block;
}

.produto .produto-pagination li.active,
.produto .produto-pagination li:hover {
    background: #00ADEF;
    border: 1px solid #00ADEF;
}

.produto .produto-pagination li.active a,
.produto .produto-pagination li:hover a {
    color: #fff;
}

.produto .produto-pagination li.disabled {
    background: #fff;
    border: 1px solid white;
}

.produto .produto-pagination li.disabled i {
    color: #f1f1f1;
    padding: 10px 16px;
    display: inline-block;
}

.produto .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
}

.produto .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #545454;
    position: relative;
}

.produto .sidebar .sidebar-item {
    margin-bottom: 30px;
}

.produto .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
    border-radius: 50px;
}

.produto .sidebar .search-form form input[type="text"] {
    border: 0;
    padding: 4px;
    width: calc(100% - 40px);
}

.produto .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: -2px;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 25px;
    margin: -1px;
    background: #545454;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

.produto .sidebar .search-form form button:hover {
    background: #00ADEF;
}

.produto .sidebar .categories ul {
    list-style: none;
    padding: 0;
}

.produto .sidebar .categories ul li+li {
    padding-top: 10px;
}

.produto .sidebar .categories ul a {
    color: #949494;
}

.produto .sidebar .categories ul a:hover {
    color: #00ADEF;
}

.produto .sidebar .categories ul a span {
    padding-left: 5px;
    color: #bababa;
    font-size: 14px;
}

.produto .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
}

.produto .sidebar .recent-posts img {
    width: 80px;
    float: left;
}

.produto .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
}

.produto .sidebar .recent-posts h4 a {
    color: #212121;
    transition: 0.3s;
}

.produto .sidebar .recent-posts h4 a:hover {
    color: #00ADEF;
}

.produto .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #bababa;
}

.produto .sidebar .tags {
    margin-bottom: -10px;
}

.produto .sidebar .tags ul {
    list-style: none;
    padding: 0;
}

.produto .sidebar .tags ul li {
    display: inline-block;
}

.produto .sidebar .tags ul a {
    color: #949494;
    font-size: 14px;
    padding: 6px 15px 8px 15px;
    margin: 0 6px 8px 0;
    border: 1px solid #ededed;
    border-radius: 50px;
    display: inline-block;
    transition: 0.3s;
}

.produto .sidebar .tags ul a:hover {
    color: #fff;
    border-color: #00ADEF;
    background: #00ADEF;
}

.produto .sidebar .tags ul a span {
    padding-left: 5px;
    color: #ededed;
    font-size: 14px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    background: #404040;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
}

#footer .footer-newsletter {
    padding: 50px 0;
    background: #404040;
}

#footer .footer-newsletter h4 {
    font-size: 24px;
    margin: 0 0 20px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
}

#footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
}

#footer .footer-newsletter form input[type="email"] {
    border: 0;
    padding: 8px;
    width: calc(100% - 140px);
}

#footer .footer-newsletter form input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 30px;
    margin: 3px;
    background: #00ADEF;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
}

#footer .footer-newsletter form input[type="submit"]:hover {
    background: #00ADEF;
}

#footer .footer-top {
    background: #3b3b3b;
    border-top: 1px solid #474747;
    border-bottom: 1px solid #474747;
    padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
    margin-bottom: 30px;
}

#footer .footer-top .footer-info h3 {
    font-size: 18px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
}

#footer .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #fff;
}

#footer .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #545454;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
    background: #00ADEF;
    color: #fff;
    text-decoration: none;
}

#footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 12px;
}

#footer .footer-top .footer-links {
    margin-bottom: 30px;
}

#footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

#footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #00ADEF;
    font-size: 18px;
    line-height: 1;
}

#footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

#footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
}

#footer .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
}

#footer .footer-top .footer-links ul a:hover {
    color: #00ADEF;
}

#footer .footer-top .footer-contact {
    margin-bottom: 30px;
}

#footer .footer-top .footer-contact p {
    line-height: 26px;
}

#footer .copyright {
    text-align: center;
    padding-top: 30px;
}

#footer .credits {
    padding-top: 10px;
    text-align: center;
    font-size: 13px;
    color: #fff;
}
.cursor-pointer{
    cursor: pointer;
}

.blog-pagination ul {
    display: flex;
    padding-left: 0;
    list-style: none;
}

.blog-pagination {
    color: #878787;
}

.blog-pagination li.disabled {
    background: #fff;
    border: 1px solid white;
}
.blog-pagination li {
    border: 1px solid white;
    margin: 0 5px;
    transition: 0.3s;
}

.blog-pagination li.active, .blog-pagination li:hover {
    background: #00ADEF;
    border: 1px solid #00ADEF;
}

.blog-pagination li.active {
    background: white;
}
.blog-pagination li {
    border: 1px solid white;
    margin: 0 5px;
    transition: 0.3s;
}

.blog-pagination li.active a, .blog-pagination li:hover a {
    color: #fff;
}

.blog-pagination li a, .blog-pagination li span {
    color: #aaaaaa;
    padding: 7px 16px;
    display: inline-block;
}